import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  OAuthProvider 
} from 'firebase/auth';
import { auth, microsoftProvider } from '../firebase';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isFormSub, setFormSub] = useState(false);

  const removedata = () => {
    signOut(auth)
  }

  const googleSignIn = () => {
    setFormSub(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider);
  };

  const googleSignedUser = () => {
    setFormSub(false);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const logOut = () => {
    signOut(auth)
  }

  // Mslogin
  // const microsoftSignIn = async () => {
  //   try {
  //     setFormSub(true);
  //     // const provider = new MicrosoftAuthProvider();
  //     const result = await signInWithPopup(auth, microsoftProvider);
  //     console.log('User info:', result.user);
  //   } catch (error) {
  //     console.error('Error during login:', error);
  //   }
  // };

  // const microsoftSignedUser = async () => {
  //   try {
  //     setFormSub(false);
  //     // const provider = new MicrosoftAuthProvider();
  //     const result = await signInWithPopup(auth, microsoftProvider);
  //     console.log('User info:', result.user);
  //   } catch (error) {
  //     console.error('Error during login:', error);
  //   }
  // };
  // Mslogin

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //if(currentUser)
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, googleSignedUser, removedata, user, isFormSub }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};