// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, OAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCogzjq7BGyEzM8TVf23S2VmhO38PcqH1c",
  authDomain: "interview-7d0b2.firebaseapp.com",
  projectId: "interview-7d0b2",
  storageBucket: "interview-7d0b2.appspot.com",
  messagingSenderId: "141496720652",
  appId: "1:141496720652:web:5a3ccdee8171da062abd29",
  measurementId: "G-KH4CEN26VN"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getFirestore(app);

// newAddedMS
// const provider = new OAuthProvider("microsoft.com");

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: "consent",
  tenant: "a28e6f27-7914-40eb-8b05-dc4a98d7b241",
});
// newAddedMS

// import firebase from 'firebase/app';
// import 'firebase/auth';

// const firebaseConfig = {
//     apiKey: "AIzaSyA76trVdjqyrqqF6vDGJK4iVFan4bxXZMY",
//     authDomain: "interview-af13e.firebaseapp.com",
//     projectId: "interview-af13e",
//     storageBucket: "interview-af13e.appspot.com",
//     messagingSenderId: "1095139084598",
//     appId: "1:1095139084598:web:dfab235e1a8150b0f54b97",
//     measurementId: "G-WPDEDWJXY1"
// };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// mslogin
// const secondaryAppConfig  = {
//   apiKey: "AIzaSyAWga21acDKGhY7aqGOtVdnMmCF0HGRWKY",
//   authDomain: "live-35345.firebaseapp.com",
//   projectId: "live-35345",
//   storageBucket: "live-35345.appspot.com",
//   messagingSenderId: "786978845564",
//   appId: "1:786978845564:web:c0d40d778d1ba4b756aaf9",
//   measurementId: "G-53V6QZ7FZX",
// };

export {
  microsoftProvider
};



