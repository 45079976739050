import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import AWS from 'aws-sdk';
import { Buffer } from "buffer";
import axios from 'axios';
import { UserAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';

const VideoRecorder = ({ getverify, randomNum, getRandom }) => {
  const [video, setVideo] = useState(null);
  const [videofors3, setVideofors3] = useState(null);

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [frames, setFrames] = useState([]);
  const [isfaceMatched, setMatchFace] = useState(false);
  const [isTextMatched, setMatchText] = useState(false);
  const { user, logOut } = UserAuth();
  const [isLoading, setLoading] = useState(false);

  const [capturebtn, setCapturebtn] = useState(false);
  const [cancelbtn, setCancelbtn] = useState(false);

  let matchingFace = false;
  let matchingText = false;
  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(captureFrame, 2000);
      return () => clearInterval(interval);
    }
  }, [isRecording]);

  useEffect(() => {
    if (isRecording) {
      const timer = setTimeout(() => {
        console.log('Delayed message after 10 seconds');
        handleStopRecording();
      }, 10000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isRecording]);

  const handleRecord = () => {
    matchingFace = false;
    matchingText = false;
    setIsRecording(true);
    setFrames([]);
    setVideo(null);
    setVideofors3(null);
    setCapturebtn(false)
    setCancelbtn(false)
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then((stream) => {
        webcamRef.current.srcObject = stream;
        const options = { mimeType: "video/webm" };
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.addEventListener("stop", handleStop);
        mediaRecorderRef.current.start();

      })
      .catch((error) => {
        console.error("Could not access camera:", error);
        toast.error('To proceed, we need permission to access your camera.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setIsRecording(false);
      });
    document.getElementById("uniqueCode").style.display = "none";
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.removeEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.removeEventListener("stop", handleStop);
    // stop the media stream
    const stream = webcamRef.current.srcObject;
    stream.getTracks().forEach(track => track.stop());
  };

  const handleDataAvailable = (event) => {
    setVideo(URL.createObjectURL(event.data));

    const videoBlob = new Blob([event.data], { type: 'video/webm' });
    const videoFile = new File([videoBlob], `${user?.uid}.webm`, { type: 'video/webm' });
    setVideofors3(videoFile);
    uploadToS3(videoFile);

    //uploadToS3(URL.createObjectURL(event.data));
  };

  const uploadToS3 = async (videoFile) => {
    setLoading(true);
    setCapturebtn(true)
    setCancelbtn(true)
    const formData = new FormData();
    formData.append('file', videoFile);
    formData.append('bucketName', 'interviewer-profile');
    formData.append('key', `${user?.uid}/videos/${user?.uid}.webm`);
    formData.append('contentType', 'video/webm');
    formData.append('isEncoding', false);
    formData.append('ContentEncoding', '');


    // Get Firebase authentication token
    const refreshedToken = await user?.getIdToken(true);

    // Set the authorization header with the token
    const headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'multipart/form-data', // Set content type to form data
    };
    try {
      const response = await axios.post(process.env.REACT_APP_S3UPLOAD, formData, { headers });
      // const response = await axios.post(`${process.env.REACT_APP_UPLOAD}/api/v3.0/s3/upload`, formData, { headers });
      setLoading(false);
      setCancelbtn(false)
    } catch (err) {
      console.error("Video upload failed:", err);
      setLoading(false);
      setCancelbtn(false)
    }
  };

  const handleStop = () => {
    setIsRecording(false);
    console.log("called in start or stop ");
    if (videofors3)
      uploadToS3(videofors3);
  };

  const compareFaces = async (imageData) => {
    try {
      const base64Data = imageData;
      if (!user || !user.uid) {
        throw new Error("User is not authenticated.");
      }
      const refreshedToken = await user?.getIdToken(true);
      const formData = new FormData();
      formData.append('file', base64Data);
      formData.append('userUid', user.uid);
      // Make a POST request to the server-side API for face comparison
      const response = await axios.post('https://dev.virtualemployee.com/admin-panel/api/v3.0/s3/compare-faces', formData, {
        // const response = await axios.post(`${process.env.REACT_APP_UPLOAD}/api/v3.0/s3/compare-faces`, formData, {
        headers: {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      // Handle the response from the server
      if (response.data && response.data.matchingFace) {
        matchingFace = true;
        setMatchFace(true);
      } else {
        console.log('No face match found');
        setMatchFace(false);
      }
    } catch (error) {
      alert("There was a problem comparing faces with your uploaded profile photo.");
      console.error("Error comparing faces:", error);
      // Handle the error gracefully, e.g., set a state variable to indicate an error.
    }
  };

  const detectText = async (imageData) => {
    try {
      if (!user || !user.uid) {
        throw new Error("User is not authenticated.");
      }
      const refreshedToken = await user?.getIdToken(true);
      const formData = new FormData();
      formData.append('file', imageData);
      formData.append('randomNum', randomNum);
      // Make a POST request to the server-side API for face comparison
      const response = await axios.post('https://dev.virtualemployee.com/admin-panel/api/v3.0/s3/detect-text', formData, {
        // const response = await axios.post(`${process.env.REACT_APP_UPLOAD}/api/v3.0/s3/detect-text`, formData, {
        headers: {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data) {
        // console.log("Text Match found!",response.data?.matchingText);
        // console.log(response.data?.matchingText);
        setMatchText(response.data?.matchingText);
        matchingText = response.data?.matchingText;
      }
    } catch (err) {
      console.log(err);
    }
  };


  const captureFrame = () => {
    const videoElement = webcamRef.current.video;
    if (!videoElement || videoElement.paused || videoElement.readyState < 2) {
      return;
    }

    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(async (blob) => {
      const file = new File([blob], 'frame.png', { type: 'image/png' });
      setFrames((prevFrames) => [...prevFrames, { src: URL.createObjectURL(blob), file }]);
      console.log(" Perform detection tasks");
      // console.log(matchingText);
      if (!matchingText)
        detectText(file);
      if (!matchingFace)
        compareFaces(file);
    }, 'image/png');
  };


  const verifyNow = () => {
    getverify(isfaceMatched, isTextMatched);
  }

  // function
  const handleButtonClick = () => {
    document.getElementById("uniqueCode").style.display = "block";
  }
  // function

  return (
    <>
      <div className='video-verify-sec position-relative'>
        {isRecording ? (
          <>
            <div className='label-wrapper mb-3'>
              <div className='alert alert-danger'>Write number on white paper and hold below your face and show to the camera . Make sure your face and the written paper both are visible at a time . The number is <span className="fw-bold text-uppercase">{randomNum}</span></div>
            </div>
            <div className="ratio ratio-16x9 border border-1 rounded-3 overflow-hidden">
              <Webcam className="d-block object-fit-cover"
                audio={false}
                ref={webcamRef}
                onUserMedia={() => console.log("Webcam started")}
              />
            </div>
          </>
          // <div className="ratio ratio-4x3 border border-1 rounded-3 overflow-hidden">
          //   <Webcam
          //     audio={false}
          //     ref={webcamRef}
          //     onUserMedia={() => console.log("Webcam started")}
          //   />
          // </div>
        ) : null}

        {/* {frames.map((frame, index) => (
        <img key={index} src={frame.src} alt="frame" />
      ))} */}
        {video && (
          <div className="ratio ratio-16x9 border-1 rounded-3 overflow-hidden">
            <video className="d-block object-fit-cover"
              ref={(el) => (el ? (el.srcObject = null) : null)}
              src={video}
              autoPlay
              loop
              controls
            />
          </div>
        )}
      </div>
      <div className='btn-wrapper d-flex flex-column flex-sm-row justify-content-center gap-2 mt-3'>
        {isRecording ? (
          <button className="btn btn-primary py-2 px-3" onClick={handleStopRecording}>Stop Recording <i className="bi bi-stop-circle"></i></button>
        ) : (
          // <button className="btn btn-primary py-2 px-3" onClick={handleRecord}>Start Recording <i class="bi bi-record-circle"></i></button>
          <>
            <button className="btn btn-success py-2 px-3" onClick={handleRecord} disabled={capturebtn}>Capture <i className="bi bi-record-circle"></i></button>
            <button type="button" className="btn btn-danger py-2 px-3" id="ClosePreview" data-bs-dismiss="modal"  disabled={cancelbtn} onClick={() => {
              setVideo(null);
              matchingText = false;
              matchingFace = false;
              setMatchText(false);
              setMatchFace(false);
              setIsRecording(false);
              getRandom();
              setCapturebtn(false)
              handleButtonClick()
            }}>Cancel</button>
          </>

        )}
        {isfaceMatched || isTextMatched ? <button type="button" className="btn btn-success lh-1 py-2 px-3" data-bs-dismiss="modal" onClick={() => { verifyNow() }}>{isLoading ? <div className='spinner-border border-2 text-light' role='status' style={{ width: '20px', height: '20px' }}><span className='visually-hidden'>Loading...</span></div> :
          "Verify Now"}</button> : null}
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default VideoRecorder;
