import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileContext from '../context/ProfileContext';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';
import axios from 'axios';

const Profile = () => {
  const [currentCompany, setCurrentCompany] = useState('');
  const [totalExperience, setTotalExperience] = useState(0);

  const navigate = useNavigate();
  const { user, logOut } = UserAuth();
  const { setProfile, profile } = useContext(ProfileContext);

  const handleSubmit = () => {
    setProfile({ currentCompany, totalExperience });
  };

  const handleScreen = () => {
    navigate('/screen');
  }
  useEffect(() => {
    if(user?.accessToken)
    fetchUserInfo();
  }, [user])
    //Fetch UserInfo
    const fetchUserInfo = async () => {
      try {
        const refreshedToken = await user?.getIdToken(true); // Assuming you have the refreshed token available
        const headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json',
        };
    
        const response = await axios.post(process.env.REACT_APP_APIURL, {
        // const response = await axios.post(`${process.env.REACT_APP_DESKTOP_API}/fetchUser`,{
          operation: 'fetchuser',
          email: user?.email,
        }, {
          headers: headers,
        });
    
        // const userData = response.data.data[0]; //test
        // console.log(userData)
        const userData = response.data[0]; //live
        const InterviewerInfo = {
          ...profile,
          prefered_lang: userData?.prefered_lang,
          is_profile_verified: userData?.is_video_verified,
        };
    
        setProfile(InterviewerInfo);
      } catch (error) {
        console.error(error);
      }
    };
  const langArray = ["JavaScript", "Python", "Java", "C++", "PHP", "Ruby", "Swift", "Objective-C", "Go", "Kotlin", "C#", "Rust", "Scala", "Perl", "TypeScript", "SQL"]; 

  return (
    <div className='profile-page py-5'>
      <div className='container'>
        <div className='card profile-form-wrapper mx-auto py-4 px-3 p-md-5'>
          {/* <div className='heading-title fs-2 fw-bold d-flex align-items-center justify-content-between gap-2 mb-3'>Profile <button onClick={handleScreen} className='btn btn-secondary'>Go To Screen</button></div> */}
          <div className="form-group mb-3">
                    <label className="form-label">Select your prefered programing language</label>
                    <select className="form-select" aria-label="Default select example" 
                    onChange={(e)=>{
                      }}
                      value={profile?.prefered_lang}
                   >
                  <option selected >Open this select menu</option>
                     {langArray?.map((lang)=>{
                      return (<option value={lang}>{lang}</option>)
                     })} 
                    </select>
                  </div>
          <div className="form-group mb-3">
            <label className="form-label"> Total Experience:</label>
            <select className='form-select' value={profile?.total_exp} onChange={(e) => setTotalExperience(Number(e.target.value))}>
              {[...Array(21)].map((_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div className='btn-wrapper'>
            <button className='btn btn-primary px-3' onClick={()=>{navigate('/account');}}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
